<template>
  <v-tabs
    v-model="currVal"
    background-color="transparent"
    grow
  >
    <v-tab
      v-for="(item, index) in items"
      :key="index"
      :value="item.name"
      class="text-subtitle-1"
      @click="onClick(item, index)"
    >
      {{ item.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
  export default {
    props: {
      active: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data () {
      return {
        currVal: '',
      }
    },
    watch: {
      active: {
        handler (val) {
          this.currVal = val
        }
      }
    },
    methods: {
      onClick (item, index) {
        this.$emit('click', {
          item, index
        })
      },
    }
  }
</script>
